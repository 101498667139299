import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import Spec1 from "../components/spec/spec1";
import Spec2 from "../components/spec/spec2";
import Spec3 from "../components/spec/spec3";
import Spec4 from "../components/spec/spec4";
import { Helmet } from "react-helmet";

const Containar = styeld.div`
`;
const pageName = "키오스크 안내";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;

// markup
const Samkiosk = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/product-spec" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta
          property="twitter:url"
          content="https://samlab.co.kr/product-spec"
        />
      </Helmet>
      <Spec1 />
      <Spec2 />
      <Spec3 />
      <Spec4 />
    </Layout>
  );
};

export default Samkiosk;
