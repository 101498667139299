import * as React from "react";
import styled from "styled-components";
import img1 from "../../images/spec/spec3-1__img.png";
import img2 from "../../images/spec/spec3-2__img.png";
import img3 from "../../images/spec/spec3-3__img.png";
import img4 from "../../images/spec/spec3-4__img.png";
import img1__table from "../../images/spec/spec3-1__text.png";
import img1__table__mobile from "../../images/spec/spec3-1__text__mobile.png";
import img2__table from "../../images/spec/spec3-2__text.png";
import img2__table__mobile from "../../images/spec/spec3-1__text__mobile.png";
import img3__table from "../../images/spec/spec3-3__text.png";
import img3__table__mobile from "../../images/spec/spec3-1__text__mobile.png";
import img4__table from "../../images/spec/spec3-4__text.png";
import img4__table__mobile from "../../images/spec/spec3-1__text__mobile.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vw;
  .product-spec__title {
    font-size: 1.25vw; //24px
    font-family: "Pretendard-ExtraBold";
    margin-bottom: 1vw;
    display: flex;
    flex-direction: column;
  }
  .line {
    background-color: #3ae0d9;
    height: 0.3vw;
    width: 3vw;
    margin-bottom: 3vw;
  }
  .product-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    .info-title {
      font-size: 1.2vw;
      width: 100%;
      border-bottom: 1px solid #222;
      padding-bottom: 0.6vw;
      small {
        color: #555;
        font-family: "Pretendard-Regular";
        font-size: 0.8vw;
      }
    }
    .product-info__img {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6vw;
      .product-info__column {
        .name {
          display: flex;
          align-items: center;
          margin: 1.6vw 0 1vw;
          font-size: 0.8vw;
          .ico {
            width: 0.2vw;
            height: 0.7vw;
            background: linear-gradient(180deg, #9fffe8 0%, #79f6c9 100%);
            margin-right: 0.4vw;
          }
        }
        .imgs {
          display: flex;

          .pc {
            display: block;
          }
          .mobile {
            display: none;
          }
          img {
            :first-child {
              width: 5vw;
              margin-right: 1.6vw;
            }
            :nth-child(2) {
              width: 19vw;
              height: fit-content;
              object-fit: cover;
            }
          }
        }
        :not(:last-child) {
          margin-right: 3.4vw;
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    margin-top: 60vw;
    .product-spec__title {
      font-size: 5.8vw; //24px
      margin-bottom: 9vw;
    }
    .product-info {
      width: 90%;

      .info-title {
        font-size: 5.2vw;
        padding-bottom: 3vw;
        small {
          font-size: 3.8vw;
        }
      }
      .product-info__img {
        flex-direction: column;
        .product-info__column {
          justify-content: center;
          :not(:last-child) {
            margin-right: 0vw;
          }
          .imgs {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              :first-child {
                width: 20vw;
                margin-right: 0vw;
                margin-bottom: 6vw;
              }
              :nth-child(2) {
                width: 100%;
              }
            }
            .pc {
              display: none;
            }

            .mobile {
              display: block;
              margin: 0 auto;
              width: 100%;
              margin-bottom: 16vw;
            }
          }
          .name {
            margin: 8vw 0 6vw;
            .ico {
              width: 1.4vw;
              height: 3.6vw;
              margin-right: 3vw;
            }
            p {
              font-size: 4vw;
            }
          }
        }
      }
    }
    .line {
      height: 1.4vw;
      width: 9.2vw;
      margin-bottom: 6vw;
    }
  }
`;

function Spec3() {
  return (
    <Container>
      <span className="product-spec__title">제품스펙</span>
      <div className="line" />
      <div className="product-info">
        <div className="info-title">
          양면형 <small>(외부용)</small>
        </div>
        <div className="product-info__img">
          <div className="product-info__column">
            <div className="name">
              <div className="ico" />
              <p>V3(현금)</p>
            </div>
            <div className="imgs">
              <img src={img1} alt='img209'/>
              <img src={img1__table} className="pc" alt='img210'/>
              <img src={img1__table__mobile} className="mobile"  alt='img211'/>
            </div>
          </div>
          <div className="product-info__column">
            <div className="name">
              <div className="ico" />
              <p>V3(카드 전용)</p>
            </div>

            <div className="imgs">
              <img src={img2}  alt='img212'/>
              <img src={img2__table} className="pc"  alt='img213'/>
              <img src={img2__table__mobile} className="mobile"  alt='img214'/>
            </div>
          </div>
        </div>
      </div>
      <div className="product-info">
        <div className="info-title">
          양면형 <small>(내부용)</small>
        </div>
        <div className="product-info__img">
          <div className="product-info__column">
            <div className="name">
              <div className="ico" />
              <p>V3(벽걸이)</p>
            </div>

            <div className="imgs">
              <img src={img3} />
              <img src={img3__table} className="pc" />
              <img src={img3__table__mobile} className="mobile" />
            </div>
          </div>
          <div className="product-info__column">
            <div className="name">
              <div className="ico" />
              <p>V4(벽걸이)</p>
            </div>

            <div className="imgs">
              <img src={img4} />
              <img src={img4__table} className="pc" />
              <img src={img4__table__mobile} className="mobile" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Spec3;
