import * as React from "react";
import styled from "styled-components";
import img from "../../images/spec/spec4.png";

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .product-spec__title {
    margin-top: 8vw;
    font-size: 1.25vw; //24px
    font-family: "Pretendard-ExtraBold";
    margin-bottom: 0.5vw;
  }
  .product-spec__sub {
    font-size: 0.8vw;
    font-family: "Pretendard-Regular";
    margin-bottom: 2vw;
  }
  .line {
    background-color: #3ae0d9;
    height: 0.3vw;
    width: 3vw;
    margin-bottom: 3vw;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6vw;
    img {
      width: 37vw;
      margin-bottom: 3vw;
    }
    ol {
      width: 30vw;
      font-family: "Pretendard-Regular";
      font-size: 0.8vw;
      line-height: 1.6;
      li {
        margin-bottom: 1.6vw;
      }

      strong {
        font-family: "Pretendard-bold";
      }
    }
  }

  @media screen and (max-width: 460px) {
    .product-spec__title {
      font-size: 5.8vw; //24px
      margin-bottom: 3.2vw;
    }
    .product-spec__sub {
      font-size: 3.8vw; //24px
      margin-bottom: 9vw;
    }
    .line {
      height: 1.4vw;
      width: 9.2vw;
      margin-bottom: 6vw;
    }
    .main {
      img {
        width: 90%;
        margin-bottom: 9vw;
      }
      ol {
        width: 80%;
        li {
          font-size: 4.1vw;
          margin-bottom: 6vw;
        }
      }
    }
  }
`;

function Spec4() {
  return (
    <Container>
      <span className="product-spec__title">현장 설치 안내</span>
      <p className="product-spec__sub">키오스크 자동문(양방향)</p>
      <div className="line" />
      <div className="main">
        <img src={img}  alt='img222'/>
        <ol>
          <li>
            키오스크용 LAN선은 통신사 모뎀에서 키오스크로 바로 연결 되어야
            합니다.
            <br />
            <strong>
              통신사 모뎀과 키오스크용 공유기 사이에 별도의 허브 또는 유/무선
              공유기 사용금지
            </strong>
          </li>
          <li>
            설치까지 <strong>약 2주소요</strong> 됩니다.
          </li>
          <li>
            밴사(예스넷)으로 진행을 안내드리며 필요서류는 별도로 안내해
            드립니다.
          </li>
          <li>
            주문 후 담당자가 전화하여 필요 서류 접수 진행 (사업자등록증,
            좌석도면 등)을 합니다.
          </li>
          <li>
            기본셋팅은 자동문당 단방향 또는 양방향이 기본 셋팅이며, 복층 or
            출입구 분리의 경우 담당자와 상의 후 별도 견적이 필요합니다.
          </li>
        </ol>
      </div>
    </Container>
  );
}

export default Spec4;
