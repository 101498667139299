import * as React from "react";
import styled from "styled-components";
import img from "../../images/spec/spec1.png";
import icon from "../../images/spec/spec1-arrow.png";
import LeftArrow from "../../images/chevron_left.svg";
import RightArrow from "../../images/chevron_right.svg";
import useScrollFadeIn from "../useScrollFadein";
import Slider from "react-slick";

const Container = styled.div`
  background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #222;
  .titleImg {
    width: 45.8vw;
    margin: 8.8vw 0vw 4.2vw 0.5vw;
  }
  .title {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      font-family: "Pretendard-ExtraBold";
      color: #333436;
      text-align: center;
      font-size: 1.6vw; //30px;;
    }
    small {
      margin: 1.5vw 0.5vw 1vw;
      font-family: "Pretendard-Regular";
      font-size: 0.6vw; //12px;;
      text-align: end;
    }
  }
  .main {
    width: 50vw;
    /* height: 30vw; */
    background: #ffffff;
    box-shadow: 0px 24px 32px rgba(34, 34, 34, 0.08);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.6vw 4vw;

    .sub {
      margin-top: 3vw;
      font-size: 1.25vw;
      strong {
        text-decoration: underline;
      }
    }
  }
  .arrow {
    margin: 0.8vw 0;
    width: 1.1vw;
  }
  .main-mobile {
    display: none;
  }
  @media screen and (max-width: 460px) {
    .titleImg {
      width: 90vw;
      margin: 30vw 0vw 14vw;
    }
    .title {
      span {
        font-size: 5.6vw; //30px;;
      }
      small {
        margin: 2vw 0 6vw;
        font-family: "Pretendard-Regular";
        font-size: 4vw; //12px;;
        text-align: center;
      }
    }
    .main {
      display: none;
    }
    .main-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      .sub {
        text-align: center;
        font-size: 4vw;
        strong {
          text-decoration: underline;
        }
      }
      margin-bottom: 20vw;
      .main-mobile-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 24px 32px rgba(34, 34, 34, 0.08);
        border-radius: 30px;
        background: #ffffff;
        padding: 5vw 4vw;
        margin: 0 1vw;
        margin-bottom: 10vw;
        font-size: 3.6vw;
        span {
          text-align: center;
          margin-bottom: 4vw;
          font-size: 5.2vw;
        }
        img {
          width: 4.6vw;
          margin: 3vw;
        }
        .price {
          display: flex;
          justify-content: space-between;
          width: 94%;
          padding: 5vw 3vw;
          border-radius: 16px;
          background-color: #f9f9f9;
          font-family: "Pretendard-Regular";
          p:last-child {
            text-align: end;
          }
        }
        .bg-black {
          display: flex;
          flex-direction: column;
          width: 94%;
          background-color: #222;
          padding: 6vw 3vw;
          border-radius: 16px;
          color: #fff;
          .price__black {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1.2;
            small {
              font-family: "Pretendard-Regular";
              font-size: 3.4vw;
            }
            :not(:first-child) {
              margin-top: 10vw;
            }
          }
        }
      }
    }
    .slick-slider {
      margin: 0 !important;
    }
    .slick-prev,
    .slick-next {
      transform: none;
      top: 40%;
      z-index: 99;
    }
    .slick-prev {
      left: 4.5%;
    }
    .slick-next {
      right: 4.5%;
    }
    .slick-list {
      padding: 0 26.5% !important;
    }
    .slick-slide {
      transition: opacity 0.2s ease-in-out;
      :not(.slick-center) {
        opacity: 0.4;
      }
    }
  }
`;
const Grid = styled.div`
  box-sizing: border-box;
  color: ${(props) => (props.black ? "#fff" : "#222")};
  background-color: ${(props) => (props.black ? "#222" : "#f9f9f9")};
  padding: 1vw 2vw;
  border-radius: 0.8vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 3vw;
  grid-row-gap: 2vw;
  align-items: center;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  font-size: ${(props) => (props.black ? ".9vw" : "0.8vw")};
  span {
    text-align: end;
  }
  p {
    font-family: "Pretendard-Regular";
    font-size: 0.76vw;
    text-align: end;
  }
  .black {
    font-family: "Pretendard-Black";
    font-size: 0.86vw;
  }
  .color {
    color: #3ae0d9;
  }
`;
function Spec1() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        type="button"
        className={className}
        style={{
          ...style,
          zIndex: 99,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <img  style={{ width: "3vw" }} src={RightArrow} alt="arrow" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        type="button"
        className={className}
        style={{
          ...style,
          zIndex: 99,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <img
          className="leftArrow"
          style={{ width: "3vw" }}
          src={LeftArrow}
          alt="arrow"
        />
      </button>
    );
  }
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "17%",
    slidesToShow: 1.2,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Container>
      <img className="titleImg" {...animation[0]} src={img} alt="img179" />
      <div className="main">
        <div className="title">
          <span>키오스크 옵션별 판매가격</span>
          <small>(부가세 별도, 단위: 원)</small>
        </div>
        <Grid className="price-grid">
          <div></div>
          <span>양방향 카드전용</span>
          <span>양방향 현금추가</span>
          <span>단방향 카드전용</span>
          <span>단방향 현금추가</span>
          <div>기준가격 1Set</div>
          <p>13,000,000원</p>
          <p>15,000,000원</p>
          <p>7,500,000원</p>
          <p>9,500,000원</p>
        </Grid>
        <img className="arrow" src={icon} alt='img181' />
        <Grid black className="price-grid">
          <div>
            수정 샘랩가격
            <br />
            (일시불)
          </div>
          <span className="black">10,000,000원</span>
          <span className="black">11,000,000원</span>
          <span className="black">6,500,000원</span>
          <span className="black">7,500,000원</span>
          <div>
            할부금액
            <br />
            (35개월 차까지)
          </div>
          <p className="black color">278,000원</p>
          <p className="black color">305,600원</p>
          <p className="black color">180,600원</p>
          <p className="black color">208,400원</p>
        </Grid>
        <p className="sub">
          <strong>선착순 10명</strong>에게 특별 혜택을 제공해 드립니다.
        </p>
      </div>

      <div className="main-mobile">
        <div className="title">
          <span>키오스크 옵션별 판매가격</span>
          <small>(부가세 별도, 단위: 원)</small>
        </div>
        <Slider {...settings}>
          <div className="main-mobile-grid">
            <span>양방향 카드전용</span>
            <div className="price">
              <p>기준 1Set</p>
              <p>13,000,000원</p>
            </div>
            <img className="arrow" src={icon} alt='img182'/>
            <div className="bg-black">
              <div className="price__black">
                <p>수정 샘랩가격</p>
                <p className="black">10,000,000원</p>
              </div>
              <div className="price__black">
                <p>
                  할부금액
                  <br />
                  <small>(35개월 차까지)</small>
                </p>
                <p className="black color">278,000원</p>
              </div>
            </div>
          </div>
          <div className="main-mobile-grid">
            <span>양방향 현금추가</span>
            <div className="price">
              <p>기준 1Set</p>
              <p>15,000,000원</p>
            </div>
            <img className="arrow" src={icon} alt='img183'/>
            <div className="bg-black">
              <div className="price__black">
                <p>수정 샘랩가격</p>
                <p className="black">11,000,000원</p>
              </div>
              <div className="price__black">
                <p>
                  할부금액
                  <br />
                  <small>(35개월 차까지)</small>
                </p>
                <p className="black color">305,600원</p>
              </div>
            </div>
          </div>
          <div className="main-mobile-grid">
            <span>단방향 카드전용</span>
            <div className="price">
              <p>기준 1Set</p>
              <p>7,500,000원</p>
            </div>
            <img className="arrow" src={icon} alt='img183'/>
            <div className="bg-black">
              <div className="price__black">
                <p>수정 샘랩가격</p>
                <p className="black">6,500,000원</p>
              </div>
              <div className="price__black">
                <p>
                  할부금액
                  <br />
                  <small>(35개월 차까지)</small>
                </p>
                <p className="black color">180,600원</p>
              </div>
            </div>
          </div>
          <div className="main-mobile-grid">
            <span>단방향 현금추가</span>
            <div className="price">
              <p>기준 1Set</p>
              <p>9,500,000원</p>
            </div>
            <img className="arrow" src={icon} alt='img185'/>
            <div className="bg-black">
              <div className="price__black">
                <p>수정 샘랩가격</p>
                <p className="black">7,500,000원</p>
              </div>
              <div className="price__black">
                <p>
                  할부금액
                  <br />
                  <small>(35개월 차까지)</small>
                </p>
                <p className="black color">208,400원</p>
              </div>
            </div>
          </div>
        </Slider>
        <p className="sub">
          <strong>선착순 10명</strong>에게 특별 혜택을 제공해 드립니다.
        </p>
      </div>
    </Container>
  );
}

export default Spec1;
