import * as React from "react";
import styled from "styled-components";
import title from "../../images/spec/spec2.png";
import bg from "../../images/spec/spec2-bg.png";
import bgMobile from "../../images/spec/spec2-bg-mobile.png";
import no from "../../images/spec/spec2-no.png";
import yes from "../../images/spec/spec2-yes.png";
import icon from "../../images/spec/spec2-3.png";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .main-title {
    margin-top: 10vw;
    img {
      width: 34.8vw;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 6vw 0;
    .bg {
      height: 65vw;
      z-index: -99;
      position: absolute;
      top: 0;
    }
    .bgMobile {
      display: none;
    }
  }
  @media screen and (max-width: 460px) {
    .main-title {
      margin: 10vw 0 20vw;
      img {
        width: 75vw;
        height: auto;
        object-fit: cover;
      }
    }
    .main {
      .bg {
        display: none;
      }
      .bgMobile {
        display: flex;
        width: 100%;
        z-index: -99;
        position: absolute;
        top: 0;
      }
    }
  }
`;
const CompareSection = styled.div`
  display: ${(props) => (props.mobile ? "none" : "flex")};
  flex-direction: column;
  width: 58%;
  margin: 5vw;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 1.6vw; //30px;
    color: #fff;
    span {
      margin: 2.2vw 6.6vw;
      :last-child {
        strong {
          color: #033422;
        }
      }
      strong {
        color: #e9b5b5;
      }
    }
  }
  .compare-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vw;
    color: #fff;
    line-height: 1.6;
    text-align: center;
    .box {
      word-break: keep-all;
      color: #555;
      background-color: #f1f1f1;
      padding: 1vw 2vw;
      width: 17.6vw;
      border-radius: 8px;
      font-size: 0.8vw;
      font-family: "Pretendard-Regular";

      img {
        margin-bottom: 1vw;
      }
    }
    .question {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 1vw;
      }
      img {
        margin-bottom: 1vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    width: 90%;
    .compare-list {
      flex-direction: column;
      margin-top: 21vw;
      .title {
        font-size: 4vw; //30px;
        color: #fff;
        width: 100%;
        span {
          width: 50%;
          margin: 10vw 0 3vw;
          :last-child {
            strong {
              color: #033422;
            }
          }
          strong {
            color: #e9b5b5;
          }
        }
      }

      :first-child {
        margin-top: 30vw;
      }
      .question {
        font-size: 5vw;
        p {
          font-size: 5vw;
        }
      }
      .boxs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .box {
          width: 41%;
          padding: 6vw 3vw;
          font-size: 3.6vw;
          word-break: break-all;
        }
      }
    }
  }
`;
function Spec2() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <Container>
      <div className="main-title">
        <img {...animation[0]} src={title} alt='img190'/>
      </div>
      <div className="main">
        <img className="bg" src={bg} alt='img191'/>
        <img className="bgMobile" src={bgMobile} alt='img192'/>
        <CompareSection>
          <div className="title">
            <span>
              <strong>A사</strong> 키오스크
            </span>
            <div></div>
            <span>
              <strong>샘랩</strong> 키오스크
            </span>
          </div>
          <div className="compare-list">
            <div className="box">
              <img src={no} alt='img194'/>
              <p>
                잦은 잔고장은 이용자의 이탈을 초래하여
                <br /> 매장의 매출 손실로 이어지는 치명적인 문제입니다.
              </p>
            </div>
            <div className="question">
              <img src={icon} alt='img195'/>
              <p>
                고장없이
                <br />
                튼튼한가?
              </p>
            </div>
            <div className="box">
              <img src={yes} alt='img196'/>
              <p>
                샘램의 키오스크는 잔고장이 없어 매장관리가 편하며
                <br /> 인건비 절감에 도움을 줍니다.
              </p>
            </div>
          </div>
          <div className="compare-list">
            <div className="box">
              <img src={no} alt='img197'/>
              <p>
                초과 이용 후 퇴실 시 요금결제가 불가함
                <br /> (초과 이용에도 그냥 보내주는 키오스크)
              </p>
            </div>
            <div className="question">
              <img src={icon} alt='img198'/>
              <p>
                초과이용결제가
                <br />
                가능한가?
              </p>
            </div>
            <div className="box">
              <img src={yes} alt='img199'/>
              <p>
                초과 요금 발생 시 결제 후 퇴장이 가능
                <br /> (양방향 2대의 키오스크가 1대보다 저렴합니다.)
              </p>
            </div>
          </div>
          <div className="compare-list">
            <div className="box">
              <img src={no} alt='img200'/>
              <p>휴대폰번호를 이용해서 타인과 공유 </p>
            </div>
            <div className="question">
              <img src={icon} alt='img201'/>
              <p>
                지문인식으로
                <br />
                입장하는가?
              </p>
            </div>
            <div className="box">
              <img src={yes} alt='img202'/>
              <p>지문인식 기능으로 본인만 이용가능</p>
            </div>
          </div>
          <div className="compare-list">
            <div className="box">
              <img src={no} alt='img203'/>
              <p>
                말만 무인화로 CCTV로 확인하지 않는 이상
                <br /> 퇴장시간은 알 수 없음
              </p>
            </div>
            <div className="question">
              <img src={icon} alt='img204'/>
              <p>
                입·퇴장 관리가
                <br />
                가능한가?
              </p>
            </div>
            <div className="box">
              <img src={yes} alt='img205'/>
              <p>
                입퇴장 기록 뿐만 아니라 휴식기록 또한 체크 가능
                <br /> (샘클라우드의 기능)
              </p>
            </div>
          </div>
          <div className="compare-list">
            <div className="box">
              <img src={no} alt='img206'/>
              <p>
                제한적 원격관리.
                <br /> 현황파악만 가능하며 업그레이드 또한 불가
              </p>
            </div>
            <div className="question">
              <img src={icon} alt='img207'/>
              <p>
                원격이용이 쉽고
                <br />
                유지보수가 가능한가?
              </p>
            </div>
            <div className="box">
              <img src={yes} alt='img208'/>
              <p>
                원격으로 제어&고객관리
                <br /> 가맹점의 요구 반영한 업그레이드
              </p>
            </div>
          </div>
        </CompareSection>
        <CompareSection mobile>
          <div className="compare-list">
            <div className="question">
              <img src={icon} />
              <p>
                고장없이
                <br />
                튼튼한가?
              </p>
            </div>
            <div className="title">
              <span>
                <strong>A사</strong> 키오스크
              </span>
              <div></div>
              <span>
                <strong>샘랩</strong> 키오스크
              </span>
            </div>
            <div className="boxs">
              <div className="box">
                <img src={no} />
                <p>
                  잦은 잔고장은 이용자의 이탈을 초래하여 매장의 매출 손실로
                  이어지는 치명적인 문제입니다.
                </p>
              </div>

              <div className="box">
                <img src={yes} />
                <p>
                  샘램의 키오스크는 잔고장이 없어 매장관리가 편하며 인건비
                  절감에 도움을 줍니다.
                </p>
              </div>
            </div>
          </div>
          <div className="compare-list">
            <div className="question">
              <img src={icon} />
              <p>
                초과이용결제가
                <br />
                가능한가?
              </p>
            </div>
            <div className="title">
              <span>
                <strong>A사</strong> 키오스크
              </span>
              <div></div>
              <span>
                <strong>샘랩</strong> 키오스크
              </span>
            </div>
            <div className="boxs">
              <div className="box">
                <img src={no} />
                <p>
                  초과 이용 후 퇴실 시 요금결제가 불가함
                  <br /> (초과 이용에도 그냥 보내주는 키오스크)
                </p>
              </div>
              <div className="box">
                <img src={yes} />
                <p>
                  초과 요금 발생 시 결제 후 퇴장이 가능
                  <br /> (양방향 2대의 키오스크가 1대보다 저렴합니다.)
                </p>
              </div>
            </div>
          </div>
          <div className="compare-list">
            <div className="question">
              <img src={icon} />
              <p>
                지문인식으로
                <br />
                입장하는가?
              </p>
            </div>
            <div className="title">
              <span>
                <strong>A사</strong> 키오스크
              </span>
              <div></div>
              <span>
                <strong>샘랩</strong> 키오스크
              </span>
            </div>
            <div className="boxs">
              <div className="box">
                <img src={no} />
                <p>휴대폰번호를 이용해서 타인과 공유 </p>
              </div>

              <div className="box">
                <img src={yes} />
                <p>지문인식 기능으로 본인만 이용가능</p>
              </div>
            </div>
          </div>
          <div className="compare-list">
            <div className="question">
              <img src={icon} />
              <p>
                입·퇴장 관리가
                <br />
                가능한가?
              </p>
            </div>
            <div className="title">
              <span>
                <strong>A사</strong> 키오스크
              </span>
              <div></div>
              <span>
                <strong>샘랩</strong> 키오스크
              </span>
            </div>
            <div className="boxs">
              <div className="box">
                <img src={no} />
                <p>
                  말만 무인화로 CCTV로 확인하지 않는 이상 퇴장시간은 알 수 없음
                </p>
              </div>

              <div className="box">
                <img src={yes} />
                <p>
                  입퇴장 기록 뿐만 아니라 휴식기록 또한 체크 가능 (샘클라우드의
                  기능)
                </p>
              </div>
            </div>
          </div>
          <div className="compare-list">
            <div className="question">
              <img src={icon} />
              <p>
                원격이용이 쉽고
                <br />
                유지보수가 가능한가?
              </p>
            </div>
            <div className="title">
              <span>
                <strong>A사</strong> 키오스크
              </span>
              <div></div>
              <span>
                <strong>샘랩</strong> 키오스크
              </span>
            </div>
            <div className="boxs">
              <div className="box">
                <img src={no} />
                <p>제한적 원격관리. 현황파악만 가능하며 업그레이드 또한 불가</p>
              </div>

              <div className="box">
                <img src={yes} />
                <p>원격으로 제어&고객관리 가맹점의 요구 반영한 업그레이드</p>
              </div>
            </div>
          </div>
        </CompareSection>
      </div>
    </Container>
  );
}

export default Spec2;
